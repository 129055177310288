import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import UserStore from '../../stores/user'
import GameStore from '../../stores/game'
import { view } from '@risingstack/react-easy-state'
import axios from 'axios'
import GetUserGeo from '../../helpers/get-user-geo'

export default view(function GameProvider({children}) {
  const history = useHistory()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {

    axios.get(process.env.REACT_APP_GAME_API_ENDPOINT).then(e => {
      if (e.status === 200) {
        GameStore.game = e.data
        document.documentElement.style.setProperty('--fonts', e.data ? e.data.Styling.FontFamily : 'Roboto')
        document.documentElement.style.setProperty('--background', e.data ? e.data.Styling.BackgroundColour : 'rgb(190, 229, 235)')
        document.documentElement.style.setProperty('--standard-font-colour', e.data ? e.data.Styling.FontColour : 'black')
        setLoaded(true)
        GetUserGeo().then(geo => {
          // console.log('GEO', geo)
          UserStore.geo = geo
          // console.log(GetUserCountry(geo))
        }).catch(err => {
          console.log('err', err)
        })
      }
    }).catch(e => {
      window.alert('Issue loading game', e.response ? JSON.stringify(e.response.data) : null)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loaded) return

    console.log('window.location', window.location)
    const params = new URLSearchParams(window.location.search)
    // Check for game in URL parameters
    const urlRoom = params.get('room')
    if (urlRoom) history.push(`/rooms/${urlRoom}`)

    // Check if nav is disabled
    const urlNav = params.get('disableNav')
    if (urlNav) {
      console.log('nav', urlNav)
      GameStore.disableNav = true
    } else {
      GameStore.disableNav = false
    }

    // Force Name
    const urlName = params.get('name')
    if (urlName) {
      console.log('name', urlName)
      UserStore.name = urlName
    }

    // Force Colour
    const urlColour = params.get('colour')
    if (urlColour) {
      console.log('colour', urlColour)
      UserStore.colour = urlColour
    }

    // Disable Transcripts
    // Bump
    const urlDisableTranscripts = params.get('disableTranscripts')
    if (urlDisableTranscripts) {
      console.log('urlDisableTranscripts', urlDisableTranscripts)
      GameStore.disableTranscriptions = true
    } else {
      GameStore.disableTranscriptions = false
    }

    // Background Colour
    const urlBackgroundColour = params.get('backgroundColour')
    if (urlBackgroundColour) {
      console.log('urlBackgroundColour', urlBackgroundColour)
      document.documentElement.style.setProperty('--background', urlBackgroundColour)
    }

    // Text Colour
    const urlTextColour = params.get('textColour')
    if (urlTextColour) {
      console.log('urlTextColour', urlTextColour)
      document.documentElement.style.setProperty('--standard-font-colour', urlTextColour)
    }

    // Disable the player list
    const disablePlayerList = params.get('disablePlayerList')
    if (disablePlayerList) {
      console.log('disablePlayerList', disablePlayerList)
      GameStore.disablePlayerList = true
    }
  }, [loaded, history])
  return loaded ? children : null
})
