import React from 'react'
import { view } from '@risingstack/react-easy-state'
import {Link} from 'react-router-dom'
import layout from './layout.module.scss'
// import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader'
// import GetCountryFromISO from '../../helpers/get-country-from-iso'
import GameStore from '../../stores/game'
import UserStore from '../../stores/user'
import GoogleFontLoader from 'react-google-font-loader'

export default view(function Layout(props) {
  return (
    <>
      <div className={layout.layout}>
        <GoogleFontLoader fonts={[{
          font: GameStore.game ? GameStore.game.Styling.FontFamily : 'Roboto',
          weights: [400, '400i'],
        }]} />
        <div className={layout.nav}>
          <img alt="" className={layout.logo} src={GameStore.game ? GameStore.game.Styling.Logo : null} />
          {
            !GameStore.disableNav ? <>
              {
                UserStore.setup ? <Link to="/rooms">Chat Rooms</Link> : <Link to="/profile">Your Profile</Link>
              }
            </> : null
          }
          {/* {!!UserStore.name ?  : null} */}
        </div>
        <div>
          {props.children}
        </div>
      </div>
    </>
  )
})
